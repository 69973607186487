body {
  font-family: 'Cabin', sans-serif;

  overflow: hidden;
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  background: lightgray;
}

h1 {
  color: slategray;
  font-size: 40vh;
}
