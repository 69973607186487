body {
  background: #d3d3d3;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  font-family: Cabin, sans-serif;
  display: flex;
  position: absolute;
  overflow: hidden;
}

h1 {
  color: #708090;
  font-size: 40vh;
}

/*# sourceMappingURL=index.4f855bda.css.map */
